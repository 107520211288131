import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Descriptions, PageHeader, Row, Skeleton, Table, Tabs, Timeline} from 'antd';
import UserService from "./userService";
import moment from "moment";
import {
    CalendarTwoTone,
    CheckCircleTwoTone,
    HourglassTwoTone,
    PauseCircleTwoTone, StepBackwardOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import ReportsListview from "./ReportsListview";
import SatisfactionListview from "./SatisfactionListview";
import DeliveriesTable from "./shared/DeliveriesTable";

const clientLookupByBSN = (bsn, uvvId) => {
    let url = "/v1/client?bsn=" + bsn;

    if (uvvId) {
        url = "/v1/client?bsn=" + bsn + "&uvvId=" + uvvId
    }

    return UserService.authFetch(url, {
        method: "get"
    });
};

const CareRequest = (props) => {
    const [careRequest, setCareRequest] = useState({
        patient_id: '',
        dob: '',
        name: '',
        initials: '',
        method: '',
        diagnosis: '',
        medication: '',
        frequency_period: '',
        frequency_times: '',
        frequency_info: '',
        frequency_amount: '',
        frequency_unit: '',
        appointment_date_: '',
        delivery_date_: '',
        appointments: [],
        reports: [],
        dateReceivedShort: ""
    });
    const [showDeliveriesForm, setShowDeliveriesForm] = useState(false)

    const appointmentColumns = [
        {
            title: 'Datum afspraak',
            key: 'date',
            render: (index, record) => {
                let m = false;
                if (record.dateAppointment !== "Nog in te plannen") {
                    m = moment(record.dateAppointment, "DD-MM-YYYY HH:mm");
                }
                else {
                    return <span>Nog in te plannen</span>
                }

                return (
                    <>
                        <span>{m.format("dddd D MMMM") + " om " + m.format("HH:mm") + "u"}</span>
                        <br />
                        <small>{m && m.fromNow()}</small>
                    </>
                );
            }
        },
        {
            title: 'Organisatie',
            dataIndex: 'organisation',
            key: 'organisation',
        },
        {
            title: 'Status',
            key: 'status',
            render: (index, record) => {
                let icon = <span></span>;
                if (record.status === "Actief") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }
                if (record.status === "On hold") {
                    icon = <PauseCircleTwoTone style={{marginRight: 5}} twoToneColor={"#ff0000"} />;
                }
                if (record.status === "Nieuw") {
                    icon = <HourglassTwoTone style={{marginRight: 5}} twoToneColor={"#ffae00"} />;
                }
                if (record.status === "Toegewezen") {
                    icon = <UserSwitchOutlined style={{marginRight: 5, color: "#8fa6ff"}} />;
                }
                if (record.status === "Ingepland") {
                    icon = <CalendarTwoTone style={{marginRight: 5}} twoToneColor={"#8fa6ff"} />;
                }
                if (record.status === "Afgerond") {
                    icon = <CheckCircleTwoTone style={{marginRight: 5}} twoToneColor={"#71ff7d"} />;
                }

                return (
                    <>
                        {icon} <span>{record.status}</span>
                    </>
                )
            }
        },
        {
            title: 'Opmerking VPK',
            dataIndex: 'annotation_vpk',
            key: 'annotation_vpk',
        },
        {
            title: 'Opmerking Desk',
            dataIndex: 'annotation_desk',
            key: 'annotation_desk',
        },
    ];

    const reportColumns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Tijd',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Datum volgende afspraak',
            dataIndex: 'dateNextAppointment',
            key: 'dateNextAppointment',
        },
        {
            title: 'Verslag',
            dataIndex: 'report',
            key: 'report',
        },
    ];

    const alertColumns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Tijd',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Bijwerking',
            dataIndex: 'report',
            key: 'report',
        },
    ];

    const deliveries = [
        {
            id: 4,
            patientId: "YLP-100001",
            status: "Nieuw",
            deliveryBefore: "22-04-2021 11:00",
            type: "Supplies",
            preparationsPlanned: "20-04-2021 12:00",
            preparationsDone: "20-04-2021 12:10",
            pickupPlanned: "21-04-2021 12:00",
            pickupDone: "21-04-2021 12:05",
            deliveryPlanned: "22-04-2021 08:00",
            deliveryDone: "22-04-2021 08:20",
        },
    ];

    useEffect(() => {
        async function getCareRequest() {
            const request = await clientLookupByBSN(props.careRequest.bsn, props.careRequest.key);
            const response = await request.json();

            console.log(response);

            setCareRequest(response);
        }

        getCareRequest();
    }, []);

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title={careRequest.eigen_referentie && careRequest.eigen_referentie.length ? `${careRequest.patient_id} / ${careRequest.eigen_referentie}` : careRequest.patient_id}
                subTitle={"Detailpagina"}
                extra={[
                    <Button icon={<StepBackwardOutlined/>} key="1" type="secondary" onClick={() => props.onClose()}>
                        Terug naar overzicht
                    </Button>,
                ]}
            />

            <Row gutter={[16,8]}>
                <Col span={6}>
                    <Row gutter={[16,8]}>
                        <Col>
                            <Card>
                                <h2>{careRequest.name}, {careRequest.initials}</h2>
                                <label>
                                    BSN
                                    <h4>{careRequest.bsn}</h4>
                                </label>
                                <label>
                                    Geboortedatum
                                    <h4>{careRequest.dob}</h4>
                                </label>
                                <label>
                                    Adres
                                    <h4>{careRequest.address} {careRequest.housenumber}</h4>
                                    <h4>{careRequest.zipcode} {careRequest.city}</h4>
                                </label>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{paddingTop: 20}}>
                                <Timeline>
                                    <Timeline.Item color="green"><strong>{careRequest.dateReceivedShort}</strong> Aanmelding verzoek</Timeline.Item>
                                </Timeline>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <Tabs defaultActiveKey="1" animated={false}>
                        <Tabs.TabPane tab="Details verzoek" key="1">
                            <Col>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="Extra informatie m.b.t. de dosering en/of frequentie">{careRequest.frequency_info || "-"}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="Leverdatum medicatie">{careRequest.delivery_date_}</Descriptions.Item>
                                    <Descriptions.Item label="Gewenste uitvoeringsdatum">{careRequest.appointment_date_}</Descriptions.Item>
                                    <Descriptions.Item label="Einddatum recept">-</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bags" key="7">
                            <Tabs animated={false}>
                                {props.careRequest.bags.map((bag) => {
                                    return (
                                        <Tabs.TabPane tab={bag.name} key={bag.id}>
                                            {bag.bag_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Main bag">{bag.bag_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.bag_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.bag_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive1a_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 1a">{bag.additive1a_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive1a_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive1a_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive1b_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 1b">{bag.additive1b_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive1b_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive1b_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive2_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 2">{bag.additive2_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive2_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive2_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive3_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 3">{bag.additive3_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive3_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive3_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive4_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 4">{bag.additive4_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive4_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive4_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.additive5_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Additive 5">{bag.additive5_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.additive5_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.additive5_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.flush1_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Flush 1">{bag.flush1_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.flush1_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.flush1_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                            {bag.flush2_bag && (
                                                <Descriptions layout="vertical" bordered>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Flush 2">{bag.flush2_bag || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Volume added">{bag.flush2_volume || "-"}</Descriptions.Item>
                                                    <Descriptions.Item style={{ width: "33%" }} label="Mmol">{bag.flush2_mmol || "-"}</Descriptions.Item>
                                                </Descriptions>
                                            )}
                                        </Tabs.TabPane>
                                    )
                                })}
                            </Tabs>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Afspraken" key="2">
                            <Table
                                columns={appointmentColumns}
                                dataSource={props.careRequest.appointments} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Verslagen" key="3">
                            <ReportsListview
                                hidePatientId={true}
                                reports={props.careRequest.reports.filter(r => r.report && r.report.length && (!r.adverse_event || !r.adverse_event.length))}
                                loading={false}
                                showReport
                                showNextAppointment
                                showDetailHeader={false} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Meldingen" key="4">
                            <ReportsListview
                                hidePatientId={true}
                                reports={props.careRequest.reports.filter(r => r.adverse_event && r.adverse_event.length)}
                                showAdverseEvent
                                loading={false}
                                showDetailHeader={false} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Tevredenheid" key="5">
                            <SatisfactionListview
                                reports={props.careRequest.reports.filter(r => r.satisfaction)}
                                loading={false} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Leveringen" key="6">
                            <DeliveriesTable
                                deliveries={[]}
                                showForm={showDeliveriesForm}
                                setShowForm={setShowDeliveriesForm}
                                showPatientId={false}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default CareRequest;
