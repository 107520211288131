import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    notification, Popconfirm,
    Result,
    Row,
    Select,
    Steps,
    Switch,
    Table,
    Upload
} from 'antd';
import {useTranslation} from 'react-i18next';
import TextArea from "antd/lib/input/TextArea";
import UserService from "./userService";
import moment from "moment";
import 'moment/locale/nl';
import locale from 'antd/es/date-picker/locale/nl_NL';
import {
    FileDoneOutlined,
    MedicineBoxOutlined,
    UserOutlined,
    UploadOutlined,
    PartitionOutlined, ShoppingCartOutlined, ShoppingOutlined
} from "@ant-design/icons";

const { Option } = Select;
const { Step } = Steps;

const mainBagListOptions = [
    "Olimel N4E 2,000 ml",
    "Olimel N4E * 2,500 ml",
    "Olimel N5E 2,000 ml",
    "Olimel N7E 1,000 ml",
    "Olimel N7E 1,500 ml",
    "Olimel N7E 2,000 ml",
    "Olimel N9 2,000 ml",
    "Olimel N9E 1,000 ml",
    "Olimel N9E 1,500 ml",
    "Olimel N9E 2,000 ml",
    "Olimel N12E 1000ml",
    "Olimel N12E 1500ml",
    "Olimel N12E 2000ml",
    "Olimel N12 1000ml",
    "Omegomel E 1085ml",
    "Omegomel E 1435ml",
    "Omegomel E 1820ml",
    "Periomegomel E 1450ml",
    "Periomegomel E 2020ml",
    "Clinimix N14G30 2,000 ml",
    "Clinimix N14G30E 2,000 ml",
    "Clinimix N17G35E 2,000 ml",
];

const additiveVitaminListOptions = [
    "none",
    "Cernevit vial 5 ml",
    "Vitamin K 10mg in 1ml",
];

const additiveTraceListOptions = [
    "none",
    "Nutryelt ampoule 10 ml",
];

const additiveElectrolyteListOptions = [
    "Calcium Gluconate 10%",
    "Potassium Chloride 14.9%",
    "Magnesium Sulphate 50%",
    "None",
    "Glycophos vial 20 ml",
];

const additiveIvFluidListOptions = [
    "Glucose 5% bag",
    "NaCl 0.9% bag",
    "Water for Inj.",
];

const infusionFrequencyListOptions = [
    "ONCE each week",
    "TWICE each week",
    "THREE times each week",
    "FOUR times each week",
    "FIVE times each week",
    "SIX times each week",
    "ONCE each day",
    "On ALTERNATE days",
];

const weekDaysListOptions = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];

const infusionTimeListOptions = [
    "8 hours",
    "10 hours",
    "12 hours",
    "14 hours",
    "16 hours",
    "18 hours",
    "20 hours",
    "22 hours",
    "24 hours",
];

function nutritionBagTableColumns(prefix = "") {
    return [
        {
            key: "bagType",
            title: "Bag type",
            dataIndex: "bagType",
            width: 250,
        },
        {
            key: "preparedBag",
            title: "Prepared bag",
            width: 300,
            render: (text, row) => {
                let formItemName = prefix + row.rowName + "_bag"

                if (row.dropDown === 'mainBag') {
                    return (
                        <Form.Item name={formItemName}>
                            <Select
                                style={{width: "100%"}}
                                options={mainBagListOptions.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                            />
                        </Form.Item>
                    )
                }
                if (row.dropDown === 'additiveVitamin') {
                    return (
                        <Form.Item name={formItemName}>
                            <Select
                                style={{width: "100%"}}
                                options={additiveVitaminListOptions.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                            />
                        </Form.Item>
                    )
                }
                if (row.dropDown === 'additiveTrace') {
                    return (
                        <Form.Item name={formItemName}>
                            <Select
                                style={{width: "100%"}}
                                options={additiveTraceListOptions.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                            />
                        </Form.Item>
                    )
                }
                if (row.dropDown === 'additiveElectrolyte') {
                    return (
                        <Form.Item name={formItemName}>
                            <Select
                                style={{width: "100%"}}
                                options={additiveElectrolyteListOptions.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                            />
                        </Form.Item>
                    )
                }
                if (row.dropDown === 'additiveIvFluid') {
                    return (
                        <Form.Item name={formItemName}>
                            <Select
                                style={{width: "100%"}}
                                options={additiveIvFluidListOptions.map((opt) => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })}
                            />
                        </Form.Item>
                    )
                }
            },
        },
        {
            key: "volumeAdded",
            title: "Volume added (ml)",
            render: (text, row) => {
                let formItemName = prefix + row.rowName + "_volume"
                if (!row.volumeAdded === undefined) {
                    return <span />
                }
                return (
                    <Form.Item name={formItemName}>
                        <InputNumber step={0.1} />
                    </Form.Item>
                )
            }
        },
        {
            key: "mmolAdded",
            title: "mmol added",
            dataIndex: "mmolAdded",
            render: (text, row) => {
                let formItemName = prefix + row.rowName + "_mmol"
                if (!row.mmolAdded === undefined) {
                    return <span />
                }
                return (
                    <Form.Item name={formItemName}>
                        <InputNumber step={0.1} />
                    </Form.Item>
                )
            }
        },
    ];
}

const nutritionBagData = [
    [
        {
            dropDown: "mainBag",
            bagType: "Bag",
            rowName: "bag",
            preparedBag: "Clinimix N14G30 2,000 ml",
            volumeAdded: 2000,
            mmolAdded: "",
        },
        {
            dropDown: "additiveVitamin",
            bagType: "Additive 1a (vitamin)",
            rowName: "additive1a",
            preparedBag: "Cernevit vial 5 ml",
            volumeAdded: 5,
            mmolAdded: "",
        },
        {
            dropDown: "additiveVitamin",
            bagType: "Additive 1b (vitamin)",
            rowName: "additive1b",
            preparedBag: "none",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveTrace",
            bagType: "Additive 2 (Trace element)",
            rowName: "additive2",
            preparedBag: "Nutryelt ampoule 10 ml",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveElectrolyte",
            bagType: "Additive 3 (Electrolyte)",
            rowName: "additive3",
            preparedBag: "Magnesium Sulphate 50%",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveElectrolyte",
            bagType: "Additive 4 (Electrolyte)",
            rowName: "additive4",
            preparedBag: "None",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveIvFluid",
            bagType: "Additive 5 (IV Fluid)",
            rowName: "additive5",
            preparedBag: "NaCl 0.9% bag",
            volumeAdded: 0,
            mmolAdded: "",
        },
    ],
    [
        {
            dropDown: "mainBag",
            bagType: "Bag",
            rowName: "bag",
            preparedBag: "Olimel N7E 1,500 ml",
            volumeAdded: 1500,
            mmolAdded: "",
        },
        {
            dropDown: "additiveVitamin",
            bagType: "Additive 1a (vitamin)",
            rowName: "additive1a",
            preparedBag: "Cernevit vial 5 ml",
            volumeAdded: 5,
            mmolAdded: "",
        },
        {
            dropDown: "additiveVitamin",
            bagType: "Additive 1b (vitamin)",
            rowName: "additive1b",
            preparedBag: "none",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveTrace",
            bagType: "Additive 2 (Trace element)",
            rowName: "additive2",
            preparedBag: "Nutryelt ampoule 10 ml",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveElectrolyte",
            bagType: "Additive 3 (Electrolyte)",
            rowName: "additive3",
            preparedBag: "Magnesium Sulphate 50%",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveElectrolyte",
            bagType: "Additive 4 (Electrolyte)",
            rowName: "additive4",
            preparedBag: "None",
            volumeAdded: 0,
            mmolAdded: "",
        },
        {
            dropDown: "additiveIvFluid",
            bagType: "Additive 5 (IV Fluid)",
            rowName: "additive5",
            preparedBag: "NaCl 0.9% bag",
            volumeAdded: 0,
            mmolAdded: "",
        },
    ],
    [
        {
            dropDown: "mainBag",
            bagType: "Bag",
            rowName: "bag",
            volumeAdded: 1500,
            mmolAdded: "",
        },
        {
            dropDown: "additiveIvFluid",
            bagType: "IV Fluid",
            rowName: "additive5",
            preparedBag: "Cernevit vial 5 ml",
            volumeAdded: 5,
            mmolAdded: "",
        },
    ],
    [
        {
            dropDown: "mainBag",
            bagType: "Flush 1",
            rowName: "flush1",
            preparedBag: "none",
            volumeAdded: "",
            mmolAdded: "",
        },
        {
            dropDown: "mainBag",
            bagType: "Flush 2",
            rowName: "flush2",
            preparedBag: "none",
            volumeAdded: false,
            mmolAdded: false,
        },
    ],
];

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const openNotificationWithIcon = (type, title, description, placement) => {
    notification[type]({
        message: title,
        description: description,
        placement: placement || "bottomLeft",
        duration: 5,
        bottom: 50
    });
};

const clientLookupByBSN = (bsn, uvvId) => {
    let url = "/v1/client?bsn=" + bsn;

    if (uvvId) {
        url = "/v1/client?bsn=" + bsn + "&uvvId=" + uvvId
    }

    return UserService.authFetch(url, {
        method: "get"
    });
};

const getMedication = () => {
    let url = "/v1/medicatie";

    return UserService.authFetch(url, {
        method: "get"
    });
};

const CareRequestForm = (props) => {
    const { t, i18n } = useTranslation();

    const [currentStep, setCurrentStep] = useState(0);
    const [requestCompleted, setRequestCompleted] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [newPatientId, setNewPatientId] = useState(0);
    const [deliveryMethod, setDeliveryMethod] = useState('levering');
    const [medication, setMedication] = useState({items: []});
    const [appointmentDateUnknown, setAppointmentDateUnknown] = useState(false);
    const [infuus, setInfuus] = useState(false);
    const [bloedAfname, setBloedAfname] = useState(false);
    const [isBaxter, setIsBaxter] = useState(true); // todo: change to better property

    const [form] = Form.useForm();

    useEffect( () => {
        async function fetchData() {
            if (props.renewRecord) {
                await handleChangeBsn(props.renewRecord.bsn, props.renewRecord.key, false);
            }
            if (props.editRecord) {
                await handleChangeBsn(props.editRecord.bsn, props.editRecord.key, false);
                await setCurrentStep(0);
            }
            if (props.conceptRecord) {
                const conceptData = props.conceptRecord;
                if (conceptData.delivery_date) {
                    conceptData.delivery_date = moment(conceptData.delivery_date);
                }

                if (conceptData.appointment_datetime) {
                    conceptData.appointment_datetime = moment(conceptData.appointment_datetime);
                }

                if (conceptData.end_date) {
                    conceptData.end_date = moment(conceptData.end_date);
                }
                form.setFieldsValue(conceptData)
            }

            const fetch = await getMedication();
            const medication = await fetch.json();
            await setMedication(medication);

            const token = await UserService.getDecodedToken();
            setInfuus(token && token.company_id && token.company_id === 8857);
            setBloedAfname(token && token.company_id && token.company_id === 13881);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (bloedAfname) {
            form.setFieldsValue({
                diagnosis: "Overig",
                medication: "N.v.t.",
                num_appointments: "1",
                delivery_method: "verpleegkundige",
                versturen_apotheek: "Niet versturen",
                frequency_times: "1",
                frequency_period: "Eenmalig",
            });
        }
    }, [bloedAfname]);

    const handleChangeBsn = async (bsn, uvvId, notify) => {
        if (bsn.length !== 9 && bsn.length !== 8) {
            return;
        }

        const request = await clientLookupByBSN(bsn, uvvId);
        const response = await request.json();

        if (response.id) {
            let data = response;

            if (data.delivery_date) {
                data.delivery_date = moment(data.delivery_date);
            }

            if (data.appointment_date_sql) {
                data.appointment_date = moment(data.appointment_date_sql);
            }

            if (data.end_date_sql) {
                data.end_date = moment(data.end_date_sql);
            }

            const formData = {
                bsn: data.bsn,
                dob: data.dob,
                gender: data.gender,
                name: data.name,
                initials: data.initials,
                own_reference: data.eigen_referentie,
                zipcode: data.zipcode,
                housenumber: data.housenumber,
                address: data.address,
                city: data.city,
                phone_mobile: data.phone_mobile,
                phone_home: data.phone_home,
                email: data.email,
                insurance: data.insurance,
                insurance_policy: data.insurance_policy,
                weight: data.weight,
                extra_info: data.extra_info,
                diagnosis: data.diagnosis,
                medication: data.medication,
                prescriber: data.prescriber,
                prescriber_telephone: data.prescriber_telephone,
                prescriber_email: data.prescriber_email,
                agbcode: data.agbcode,
                method: data.method ? capitalizeFirstLetter(data.method) : "Injectie",
                duration: data.duration,
                frequency_times: data.frequency_times,
                frequency_period: data.frequency_period,
                frequency_amount: data.frequency_amount,
                frequency_unit: data.frequency_unit,
                frequency_info: data.frequency_info,
                num_appointments: data.num_appointments,
                urgent: data.urgent || false,
                delivery_method: data.delivery_method,
                delivery_date: data.delivery_date || undefined,
                versturen_apotheek: data.versturen_apotheek,
                appointment_date: data.appointment_date || undefined,
                appointment_date_unknown: data.appointment_date_unknown || false,
                end_date: data.end_date || undefined,
                selfcare_date: data.selfcare_date,
                vigori_meting_gewenst: data.vigori_meting_gewenst,
                port_a_cath: data.port_a_cath,
                noodmedicatie: data.noodmedicatie,
                noodmedicatie_aanwezig: data.noodmedicatie_aanwezig,
                soort_noodmedicatie: data.soort_noodmedicatie,
                dosering_noodmedicatie: data.dosering_noodmedicatie,
                epipen: data.epipen,
                dosering_epipen: data.dosering_epipen,
                uvv_id: data.uvv_id || 0,
                re_id: data.re_id || 0,
            };

            form.setFieldsValue(formData);

            if (bloedAfname) {
                form.setFieldsValue({
                    diagnosis: "Overig",
                    medication: "N.v.t.",
                    num_appointments: "1",
                    delivery_method: "verpleegkundige",
                    versturen_apotheek: "Niet versturen",
                    frequency_times: "1",
                    frequency_period: "Eenmalig",
                });
            }

            if (notify) {
                openNotificationWithIcon('success', 'Patient gevonden', 'Er is een patient gevonden voor het ingevoerde BSN. De patient-informatie is automatisch ingevoerd.')
            }
        }
    };

    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values) => {
                values.appointment_date = moment(values.appointment_date).toISOString(true);
                values.appointment_datetime = moment(values.appointment_datetime).toISOString(true);
                values.delivery_date = moment(values.delivery_date).toISOString(true);
                values.end_date = moment(values.end_date).toISOString(true);
                values.selfcare_date = moment(values.selfcare_date).toISOString(true);
                values.is_baxter = isBaxter;
                const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_new", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                });
                const response = await request.json();
                setNewPatientId(response.patient_id);
                setRequestCompleted(true);
            })
        .catch(errorInfo => {
            console.log(errorInfo)
            return openNotificationWithIcon('error', 'Fouten in formulier', 'Het formulier bevat fouten. De onjuiste invoervelden zijn met een rode tekst gemarkeerd.');
        })
    };

    let title = t('newRequest');
    title = (props.editRecord ? "Uitvoeringsverzoek aanpassen" : title);
    title = (props.renewRecord ? "Herhaalrecept aanmaken" : title);

    return (
        <Drawer
            placement={"top"}
            title={title}
            height={"100vh"}
            style={{minHeight: "700px"}}
            onClose={props.onClose}
            visible={true}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    {currentStep === 3 && (
                        <Button disabled={newPatientId !== 0} onClick={handleSubmit} style={{ marginRight: 8 }} type={"primary"} >
                            {t('submit')}
                        </Button>
                    )}
                    {currentStep !== 3 && (
                        <Button
                            onClick={async () => {
                                const formValues = form.getFieldsValue();
                                formValues.concept = true;
                                const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_new", {
                                    method: "post",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(formValues)
                                });
                                const response = await request.json();
                                if (response.uvvc_id) {
                                    openNotificationWithIcon('success', 'Tussentijds opslaan', 'Het uitvoeringsverzoek is als concept opgeslagen', 'bottomRight')
                                }
                            }}
                            type="primary">
                            Tussentijds opslaan
                        </Button>
                    )}
                    <Popconfirm onConfirm={() => props.onClose} title="Weet je zeker dat je wilt annuleren?">
                        <Button style={{ marginRight: 8 }}>
                            Annuleren
                        </Button>
                    </Popconfirm>
                    {currentStep !== 3 && (
                      <>
                          <Divider type="vertical" />
                          <Button
                              style={{ marginRight: 8 }}
                              onClick={() => setCurrentStep(() => currentStep + 1)}>
                              Volgende >
                          </Button>
                      </>
                    )}
                </div>
            }
        >
            <Steps current={currentStep} type={"navigation"} onChange={step => {
                if (step === 3) {
                    form.validateFields()
                        .then((values) => {
                            setFormValues(values);
                        });
                }
                console.log(step);
                setCurrentStep(step);
            }}>
                <Step title={t('patient')} icon={<UserOutlined />} />
                <Step title={t('medical')} icon={<MedicineBoxOutlined />} />
                <Step title="Producten" icon={<ShoppingOutlined />} />
                <Step title={t('checkAndSubmit')} icon={<FileDoneOutlined />} />
            </Steps>

            <Divider type={"horizontal"} />

            <Form layout="vertical" form={form}>
                <Form.Item name="re_id" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="uvv_id" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="uvvc_id" hidden>
                    <Input type="hidden" />
                </Form.Item>

                <div style={{display: currentStep === 0 ? "block" : "none"}}>
                    <h3>{t('name')}</h3>
                    <Row gutter={16}>
                        <Col sm={24} md={8} lg={6}>
                            <Form.Item label={"Patient identifier"} name="bsn" rules={[{ required: true, message: 'BSN is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} onChange={(e) => handleChangeBsn(e.target.value, undefined, true)} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={8} lg={6}>
                            <Form.Item label={t('dateOfBirth')} name="dob" rules={[
                                {required: true, pattern: new RegExp("^([0-2][0-9]|(3)[0-1])(\\-)(((0)[0-9])|((1)[0-2]))(\\-)\\d{4}$"), message: 'De geboortedatum moet in formaat DD-MM-JJJJ worden ingegeven'},
                            ]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={16} sm={16} md={16} lg={6}>
                            <Form.Item label="Voornaam" name="firstname" rules={[{ required: true, message: 'Voornaam is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={6}>
                            <Form.Item label="Achternaam" name="name" rules={[{ required: true, message: 'Achternaam is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={6}>
                            <Form.Item label={t('initials')} name="initials" rules={[{ required: true, message: 'Voorletters is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={6} rules={[{required: infuus}]}>
                            <Form.Item label={t('ownReference')} extra={t('ownReferenceDesc')} name="own_reference">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} rules={[{required: infuus}]}>
                            <Form.Item label="Order number" name="order_number">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Adres</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('zipCode')} name="zipcode" rules={[{ required: true, pattern: new RegExp("^[1-9][0-9]{3} ?[A-Za-z]{2}$"), message: 'De postcode moet in het formaat 1234AB worden ingegeven' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('houseNumber')} name="housenumber" rules={[{ required: true, message: 'Huisnummer is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('street')} name="address" rules={[{ required: true, message: 'Straatnaam is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('city')} name="city" rules={[{ required: true, message: 'Woonplaats is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Tweede adres</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('zipCode')} name="zipcode_2" rules={[{ required: false, pattern: new RegExp("^[1-9][0-9]{3} ?[A-Za-z]{2}$"), message: 'De postcode moet in het formaat 1234AB worden ingegeven' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('houseNumber')} name="housenumber_2">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('street')} name="address_2">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('city')} name="city_2">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Mantelzorger</h3>
                    <Row gutter={16}>
                        <Col xs={16} sm={16} md={16} lg={6}>
                            <Form.Item label="Voornaam" name="firstname_caregiver" rules={[{ required: false, message: 'Voornaam is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={6}>
                            <Form.Item label="Achternaam" name="name_caregiver" rules={[{ required: false, message: 'Achternaam is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={6}>
                            <Form.Item label={t('initials')} name="initials_caregiver" rules={[{ required: false, message: 'Voorletters is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('zipCode')} name="zipcode_caregiver" rules={[{ required: false, pattern: new RegExp("^[1-9][0-9]{3} ?[A-Za-z]{2}$"), message: 'De postcode moet in het formaat 1234AB worden ingegeven' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('houseNumber')} name="housenumber_caregiver">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('street')} name="address_caregiver">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('city')} name="city_caregiver">
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('mobilePhone')} name="phone_mobile_caregiver" rules={[{ required: false, message: 'Telefoon mobiel is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('homePhone')} name="phone_home_caregiver" rules={[{ required: false, message: '' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('email')} name="email_caregiver" rules={[{ required: false, pattern: new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"), message: 'Vul een geldig e-mailadres in' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Contact</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('mobilePhone')} name="phone_mobile" rules={[{ required: true, message: 'Telefoon mobiel is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('homePhone')} name="phone_home" rules={[{ required: false, message: '' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('email')} name="email" rules={[{ required: true, pattern: new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"), message: 'Vul een geldig e-mailadres in' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {infuus && <>
                        <h3>Overig</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label={"Gewicht"} name="weight">
                                    <InputNumber size={"large"} placeholder="" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    <h3>{t('annotations')}</h3>
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Form.Item label={t('nonMedicalAnnotations')} extra={t('nonMedicalAnnotationsDesc')} name="extra_info">
                                <TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div style={{display: currentStep === 1 ? "block" : "none"}}>
                    {!isBaxter && (
                        <>
                            <h3>Diagnose</h3>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item label={t('diagnosis')} name="diagnosis" rules={[{ required: true, message: 'Diagnose/indicatie is een verplicht veld' }]}>
                                        <Input size={"large"} placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item label={t('medication')} name="medication" rules={[{ required: true, message: 'Medicatie is een verplicht veld' }]}>
                                        <Select disabled={props.editRecord !== undefined} size={"large"} placeholder={t('pickAnOption')}>
                                            <Select.OptGroup label={"Beschikbare medicatie"}>
                                                {medication.items.filter(m => m.visible && m.selectable).sort((a, b) => {
                                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                        return -1;
                                                    }
                                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((m, index) => {
                                                    return (
                                                        <Select.Option key={index} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                    )
                                                })}
                                            </Select.OptGroup>
                                            <Select.OptGroup label={"Overig"}>
                                                {medication.items.filter(m => m.visible && !m.selectable).sort((a, b) => {
                                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                        return -1;
                                                    }
                                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((m, index) => {
                                                    return (
                                                        <Select.Option key={index + 1000} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                    )
                                                })}
                                            </Select.OptGroup>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    <h3>Voorschrijver</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('prescriber')} name="prescriber" rules={[{ required: true, message: 'Voorschrijver is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telefoon voorschrijver" name="prescriber_telephone">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="E-mailadres voorschrijver" name="prescriber_email">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Contact ziekenhuis</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Naam contactpersoon" name="hospital_main_name">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telefoon" name="hospital_main_telephone">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="E-mailadres" name="hospital_main_email" rules={[{ required: false, pattern: new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"), message: 'Vul een geldig e-mailadres in' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="AGB Code ziekenhuis" name="agbcode">
                                <InputNumber style={{width: 200}} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {!isBaxter && (
                        <>
                            <h3>Apotheek</h3>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item label="Naam apotheek" name="pharmacy_name">
                                        <Input size={"large"} placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <Form.Item label="Plaats apotheek" name="pharmacy_city">
                                        <Input size={"large"} placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {bloedAfname && (
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <span>Afnamebuizen</span>
                                <Table
                                    pagination={false}
                                    size="small"
                                    style={{marginBottom: 20, marginTop: 10}}
                                    columns={[
                                        {
                                            key: "color",
                                            title: "",
                                            render: (x, row) => {
                                                return (
                                                    <div style={{width: 20, height: 20, backgroundColor: row.colorValue, border: "1px solid #ccc"}}>&nbsp;</div>
                                                )
                                            }
                                        },
                                        {
                                            key: "color",
                                            title: "Kleur buis",
                                            render: (x, row) => {
                                                return (
                                                    <span>{row.color}</span>
                                                )
                                            }
                                        },
                                        {
                                            key: "count",
                                            title: "Aantal",
                                            width: 150,
                                            render: (x, row) => {
                                                return (
                                                    <Form.Item name={row.field}>
                                                        <Input type="number" min={0} defaultValue="0" />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                    ]}
                                    dataSource={[
                                        {
                                            colorValue: "#fff",
                                            color: "Doorzichtig",
                                            field: "vena_aantal_doorzichtig",
                                        },
                                        {
                                            colorValue: "lightBlue",
                                            color: "Lichtblauw",
                                            field: "vena_aantal_lichtblauw",
                                        },
                                        {
                                            colorValue: "red",
                                            color: "Rood",
                                            field: "vena_aantal_rood",
                                        },
                                        {
                                            colorValue: "yellow",
                                            color: "Geel",
                                            field: "vena_aantal_geel",
                                        },
                                        {
                                            colorValue: "green",
                                            color: "Groen",
                                            field: "vena_aantal_groen",
                                        },
                                        {
                                            colorValue: "blue",
                                            color: "Donkerblauw",
                                            field: "vena_aantal_donkerblauw",
                                        },
                                        {
                                            colorValue: "purple",
                                            color: "Paars",
                                            field: "vena_aantal_paars",
                                        },
                                        {
                                            colorValue: "#fff",
                                            color: "Wit",
                                            field: "vena_aantal_wit",
                                        },
                                        {
                                            colorValue: "grey",
                                            color: "Grijs",
                                            field: "vena_aantal_grijs",
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}

                    {!isBaxter && (
                        <>
                            <h3>Handeling</h3>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label={t('route')} name="method" rules={[{ required: true, message: 'Toedieningsvorm is een verplicht veld' }]}>
                                        <Select disabled={props.editRecord !== undefined} size={"large"} placeholder={t('pickAnOption')}>
                                            <Select.Option value={"Injectie"}>Injectie</Select.Option>
                                            <Select.Option value={"Instructie"}>Instructie</Select.Option>
                                            <Select.Option value={"Infuus"}>Infuus</Select.Option>
                                            <Select.Option value={"Venapunctie"}>Venapunctie</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label={"Duur (min)"} name="duration">
                                        <InputNumber size={"large"} min={0} step={1} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {!isBaxter && (
                        <>
                            <h3>{t('dosage')}</h3>
                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label={t('frequency')} name="frequency_times" rules={[{ required: true, pattern: '^[1-9]+[0-9]*$', message: 'Geef de freqentie in als getal' }]}>
                                        <Input size={"large"} addonAfter={t('timesPer')} disabled={bloedAfname} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label={t('period')} name="frequency_period" rules={[{ required: true, message: 'Periode is een verplicht veld' }]}>
                                        <Select size={"large"} placeholder={t('pickAnOption')} addonBefore={"per"} disabled={bloedAfname}>
                                            <Select.Option value={"Eenmalig"}>Eenmalig</Select.Option>
                                            <Select.Option value={"Dag"}>Dag</Select.Option>
                                            <Select.Option value={"Week"}>Week</Select.Option>
                                            <Select.Option value={"2-weken"}>2-weken</Select.Option>
                                            <Select.Option value={"3-weken"}>3-weken</Select.Option>
                                            <Select.Option value={"4-weken"}>4-weken</Select.Option>
                                            <Select.Option value={"5-weken"}>5-weken</Select.Option>
                                            <Select.Option value={"6-weken"}>6-weken</Select.Option>
                                            <Select.Option value={"8-weken"}>8-weken</Select.Option>
                                            <Select.Option value={"12-weken"}>12-weken</Select.Option>
                                            <Select.Option value={"Maand"}>Maand</Select.Option>
                                            <Select.Option value={"2-maanden"}>2-maanden</Select.Option>
                                            <Select.Option value={"3-maanden"}>3-maanden</Select.Option>
                                            <Select.Option value={"6-maanden"}>6-maanden</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {!bloedAfname && (
                                    <>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Item label={t('amount')} name="frequency_amount" rules={[{ required: !bloedAfname, message: 'Hoeveelheid is een verplicht veld' }]}>
                                                <InputNumber style={{width: "100%"}} size={"large"} min={0} step={0.01} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6}>
                                            <Form.Item label={t('unit')} name="frequency_unit" rules={[{ required: true, message: 'Eenheid is een verplicht veld' }]}>
                                                <Select size={"large"}>
                                                    <Select.Option value={"Mg"}>Mg</Select.Option>
                                                    <Select.Option value={"Ml"}>Ml</Select.Option>
                                                    <Select.Option value={"Gr"}>Gr</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </>
                    )}

                    {!isBaxter && (
                        <>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Form.Item label={"Aantal handelingen"} name="num_appointments" rules={[{ required: true, message: 'Aantal handelingen is een verplicht veld' }]}>
                                        <InputNumber style={{width: "100%"}} size={"large"} min={1} step={1} disabled={bloedAfname} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label={t('frequencyInfo')} name="frequency_info" rules={[{ required: false }]}>
                                        <TextArea rows={2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {!isBaxter && (
                        <>
                            <h3>Voorraad</h3>
                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label="Voorraad" name="stock_amount">
                                        <Input size={"large"} type="number" step={1} min={0} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <Form.Item label="Peildatum" name="stock_date">
                                        <Input size={"large"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {isBaxter && (
                        <>
                            <h3>{t('date')}</h3>
                            {!isBaxter && (
                                <Row gutter={16}>
                                    <Col xs={24} md={8}>
                                        <Form.Item label={t('urgent') + "?"} name="urgent">
                                            <label>{t('urgentDesc')}</label>
                                            <Switch disabled={props.editRecord !== undefined} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={16}>
                                {!isBaxter && (
                                    <Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item label={t('deliveryMethod')} name="delivery_method" rules={[{ required: true, message: 'Leveringswijze is een verplicht veld' }]}>
                                            <Select size={"large"} onChange={(value) => setDeliveryMethod(value)} placeholder={t('pickAnOption')}>
                                                <Select.Option value={"levering"}>{t('deliveredByHospital')}</Select.Option>
                                                <Select.Option value={"meegegeven"}>{t('patientPicksUpMedication')}</Select.Option>
                                                <Select.Option value={"extern"}>{t('externalPharmacy')}</Select.Option>
                                                <Select.Option value={"opdrachtgever"}>Opdrachtgever</Select.Option>
                                                <Select.Option value={"verpleegkundige"}>Via verpleegkundige</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                {!isBaxter && !bloedAfname && (
                                    <>
                                        <Col xs={24} sm={12} md={8} lg={4}>
                                            <Form.Item label={t('deliveryDate')} name="delivery_date" rules={[{ required: deliveryMethod === "levering", message: 'Leverdatum is een verplicht veld' }]}>
                                                <DatePicker locale={locale} disabled={deliveryMethod === "extern"} size={"large"} format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={4}>
                                            <Form.Item label={t('sendPrescriptionToPharmacy')} name="versturen_apotheek" rules={[{ required: deliveryMethod === "extern", message: 'Het is verplicht om aan te geven of het recept verstuurd moet worden.' }]} initialValue={"Niet versturen"}>
                                                <Select size={"large"} onChange={(value) => setDeliveryMethod(value)}>
                                                    <Select.Option value={"Niet versturen"}>{"Niet versturen"}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={4}>
                                            <Form.Item label={"Bestaand recept uploaden"} name="versturen_apotheek" rules={[{ required: deliveryMethod === "extern", message: 'Het is verplicht om aan te geven of het recept verstuurd moet worden.' }]} initialValue={"Niet versturen"}>
                                                <Upload>
                                                    <Button icon={<UploadOutlined />}>Bestand selecteren</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={12} md={6}>
                                    {props.editRecord !== undefined && <>
                                        <span style={{color: "red"}}>Vul hier de nieuwe gewenste uitvoeringsdatum in. Als je de datum nog niet weet, vink dan aan "Onbekend". De verpleegkundige en de patiënt ontvangen een sms van de wijziging.</span>
                                    </>}
                                    {!isBaxter && (
                                        <>
                                            <Form.Item label={t('preferentProcedureDate')} name="appointment_date" rules={[{ required: appointmentDateUnknown === false, message: 'Uitvoeringsdatum is een verplicht veld' }]}>
                                                <DatePicker locale={locale} disabled={appointmentDateUnknown} size={"large"} format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                            <Form.Item label={"Datum uitvoering onbekend?"} name="appointment_date_unknown">
                                                <Switch onChange={checked => setAppointmentDateUnknown(checked)} />
                                            </Form.Item>
                                        </>
                                    )}
                                    {isBaxter && (
                                        <Form.Item label="Datum eerste injectie" name="appointment_datetime" rules={[{ required: appointmentDateUnknown === false, message: 'Uitvoeringsdatum is een verplicht veld' }]}>
                                            <DatePicker showTime locale={locale} disabled={appointmentDateUnknown} size={"large"} format={"DD-MM-YYYY HH:mm"} />
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col xs={24} sm={12} md={6}>
                                    {isBaxter && (
                                        <Form.Item label="Apotheek" name="pharmacy_cp">
                                            <Select>
                                                <Select.Option value={0}>Own hospital</Select.Option>
                                                <Select.Option value={8656}>andyklow</Select.Option>
                                                <Select.Option value={8657}>anthony_de_paepe</Select.Option>
                                                <Select.Option value={8658}>caroline_ducret</Select.Option>
                                                <Select.Option value={8659}>marjolein_van_leuken_frerichs</Select.Option>
                                                <Select.Option value={8660}>paolo_brugiotti</Select.Option>
                                                <Select.Option value={8661}>vanessa_counaert</Select.Option>
                                                <Select.Option value={8662}>menno_bijkerk</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Col>
                                {!isBaxter && !bloedAfname && (
                                    <>
                                        <Col xs={24} sm={12} md={4}>
                                            <Form.Item label={t('prescriptionEnd')} name="end_date" rules={[{ required: false, message: '' }]}>
                                                <DatePicker locale={locale} size={"large"} format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={4}>
                                            <Form.Item label="Datum zelfzorg" name="selfcare_date" rules={[{ required: false, message: '' }]}>
                                                <DatePicker locale={locale} size={"large"} format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </>
                    )}

                    {isBaxter && (
                        <>
                            <Row>
                                <Col><h3>Parenteral Nutrition Bag 1</h3></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Table
                                        pagination={false}
                                        columns={nutritionBagTableColumns("bag1_")}
                                        dataSource={nutritionBagData[0]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <h4>Infusion frequency</h4>
                                    <Form.Item name="bag1_uvb_frequency">
                                        <Select
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={infusionFrequencyListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>

                                    <h4>Infusion days</h4>
                                    <Form.Item name="bag1_uvb_days">
                                        <Select
                                            mode="multiple"
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={weekDaysListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>

                                    <h4>Infusion time</h4>
                                    <Form.Item name="bag1_uvb_infusion_time">
                                        <Select
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={infusionTimeListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Col><h3>Parenteral Nutrition Bag 2</h3></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Table
                                        pagination={false}
                                        columns={nutritionBagTableColumns("bag2_")}
                                        dataSource={nutritionBagData[1]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <h4>Infusion frequency</h4>
                                    <Form.Item name="bag2_uvb_frequency">
                                        <Select
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={infusionFrequencyListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>

                                    <h4>Infusion days</h4>
                                    <Form.Item name="bag2_uvb_days">
                                        <Select
                                            mode="multiple"
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={weekDaysListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>

                                    <h4>Infusion time</h4>
                                    <Form.Item name="bag2_uvb_infusion_time">
                                        <Select
                                            placeholder="Maak een keuze..."
                                            style={{width: 250}}
                                            options={infusionTimeListOptions.map((opt) => {
                                                return {value: opt, label: opt}
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row style={{marginTop: 20}}>
                                <Col><h3>Emergency unprepared bag</h3></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Table
                                        pagination={false}
                                        columns={nutritionBagTableColumns("emergency_")}
                                        dataSource={nutritionBagData[2]}
                                    />
                                </Col>
                                <Col span={6}>
                                    <h4>Infusion frequency</h4>
                                    <Select
                                        placeholder="Maak een keuze..."
                                        style={{width: 250}}
                                        options={infusionFrequencyListOptions.map((opt) => {
                                            return {value: opt, label: opt}
                                        })}
                                    />

                                    <h4>Infusion days</h4>
                                    <Select
                                        mode="multiple"
                                        placeholder="Maak een keuze..."
                                        style={{width: 250}}
                                        options={weekDaysListOptions.map((opt) => {
                                            return {value: opt, label: opt}
                                        })}
                                    />

                                    <h4>Infusion time</h4>
                                    <Select
                                        placeholder="Maak een keuze..."
                                        style={{width: 250}}
                                        options={infusionTimeListOptions.map((opt) => {
                                            return {value: opt, label: opt}
                                        })}
                                    />
                                </Col>
                            </Row>

                            <Row style={{marginTop: 20}}>
                                <Col><h3>Flushes</h3></Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Table
                                        pagination={false}
                                        columns={nutritionBagTableColumns("flushes_")}
                                        dataSource={nutritionBagData[3]}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    {/* EXTRA VELDEN INFUUS */}
                    {infuus && <>
                        <h3>Infuus</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Vigori meting gewenst"} name="vigori_meting_gewenst">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Port-a-cath"} name="port_a_cath">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Noodmedicatie"} name="noodmedicatie">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Noodmedicatie aanwezig"} name="noodmedicatie_aanwezig">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    {infuus && <Row gutter={16}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Soort noodmedicatie"} name="soort_noodmedicatie">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Dosering noodmedicatie"} name="dosering_noodmedicatie">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {infuus && <Row gutter={16}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Epipen"} name="epipen">
                                <Select size={"large"} placeholder={t('pickAnOption')}>
                                    <Select.Option value={"Ja"}>Ja</Select.Option>
                                    <Select.Option value={"Nee"}>Nee</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Dosering epipen"} name="dosering_epipen">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                    </Row>}
                </div>

                <div style={{display: currentStep === 3 && !requestCompleted ? "block" : "none"}}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <h3>De arts of diens waarnemer:</h3>
                            <table style={{width: "100%", border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                    <tr><td>Naam voorschrijver</td><td>{formValues.prescriber}</td></tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col span={12}>
                            <h3>Verzoekt de zorginstelling:</h3>
                            <table style={{width: "100%", border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                <tr><td>Naam organisatie</td><td>Bleds B.V.</td></tr>
                                <tr><td>Vertegenwoordigt door </td><td>Mevrouw W.H. Kuipers</td></tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <h3>Onderstaande handeling (en) uit te voeren conform het daartoe door opgestelde protocol:</h3>
                    {!isBaxter && (
                        <Row gutter={5} style={{paddingTop: 20}}>
                            <table style={{width: 500, border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                <tr><td>Diagnose/indicatie</td><td>{formValues.diagnosis}</td></tr>
                                <tr><td>Medicatie</td><td>{formValues.medication}</td></tr>
                                <tr><td>Toedieningsvorm</td><td>{formValues.method}</td></tr>
                                <tr><td>Frequentie</td><td>{formValues.frequency_times}x per {formValues.frequency_period}, {formValues.frequency_amount} {formValues.frequency_unit}</td></tr>
                                <tr><td style={{verticalAlign: "text-top"}}>Extra info</td><td>{formValues.frequency_info}</td></tr>
                                </tbody>
                            </table>
                        </Row>
                    )}
                    {isBaxter && (
                        <Row gutter={5} style={{paddingTop: 20}}>
                            <table style={{width: 500, border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                <tr><td>Aanvraag</td><td>Baxter</td></tr>
                                </tbody>
                            </table>
                        </Row>
                    )}
                </div>

                <div style={{display: currentStep === 2 && !requestCompleted ? "block" : "none"}}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"Type TPV"} name="type_tpv">
                                <Select>
                                    <Select.Option value="Onbereid">Onbereid</Select.Option>
                                    <Select.Option value="Bereid">Bereid</Select.Option>
                                    <Select.Option value="Maatwerk">Maatwerk</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"Koelkast"} name="refrigerator">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"Is er ruimte voor 140L koelkast"} name="room_for_refrigerator">
                                <Select>
                                    <Select.Option value="Ja">Ja</Select.Option>
                                    <Select.Option value="Nee">Nee</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"Infusie pomp"} name="infusion_pump">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"Pump accessories"} name="pump_accessories">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"Line type"} name="line_type">
                                <Select>
                                    <Select.Option value="PICC">PICC</Select.Option>
                                    <Select.Option value="Hickman">Hickman</Select.Option>
                                    <Select.Option value="Portacath">Portacath</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"Voor PAC: maat van de naald"} name="needle_size">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"Disposables"} name="disposables">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label={"TPV Infusies per week"} name="tpv_infusions_per_week">
                                <Select>
                                    <Select.Option value="0">0</Select.Option>
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                    <Select.Option value="3">3</Select.Option>
                                    <Select.Option value="4">4</Select.Option>
                                    <Select.Option value="5">5</Select.Option>
                                    <Select.Option value="6">6</Select.Option>
                                    <Select.Option value="7">7</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"IV vocht Infusies per week"} name="iv_infusions_per_week">
                                <Select>
                                    <Select.Option value="0">0</Select.Option>
                                    <Select.Option value="1">1</Select.Option>
                                    <Select.Option value="2">2</Select.Option>
                                    <Select.Option value="3">3</Select.Option>
                                    <Select.Option value="4">4</Select.Option>
                                    <Select.Option value="5">5</Select.Option>
                                    <Select.Option value="6">6</Select.Option>
                                    <Select.Option value="7">7</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div style={{display: currentStep === 3 && requestCompleted ? "block" : "none"}}>
                    <Result
                        status="success"
                        title="Klaar!"
                        subTitle={"Het uitvoeringsverzoek is opgeslagen met Patient ID " + newPatientId}
                        extra={[
                            <Button type="primary" key="console" onClick={props.onClose}>
                                Terug naar het overzicht
                            </Button>,
                        ]}
                    />
                </div>

            </Form>
        </Drawer>
    );
};

export default CareRequestForm;
