import React, {useEffect, useState} from "react";
import {Button, PageHeader, Table} from "antd";
import UserService from "./userService";
import Report from "./Report";
import {CalendarOutlined, TagOutlined, HeartOutlined} from "@ant-design/icons";

const SatisfactionListview = (props) => {
    const [selectedReport, setSelectedReport] = useState(undefined);

    const columns = [
        {
            title: 'Datum',
            fixed: 'left',
            width: 150,
            visible: true,
            dataIndex: 'date',
            key: 'date',
        },
        {
            visible: true,
            title: 'Tijd',
            width: 150,
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'Tevredenheid',
            visible: true,
            key: 'satisfaction',
            render: (text, row) => {
                return (
                    <>
                        <HeartOutlined style={{ fontSize: 24, color: row.satisfaction >= 1 ? "red" : "#ccc" }} />
                        <HeartOutlined style={{ fontSize: 24, color: row.satisfaction >= 2 ? "red" : "#ccc" }} />
                        <HeartOutlined style={{ fontSize: 24, color: row.satisfaction >= 3 ? "red" : "#ccc" }} />
                        <HeartOutlined style={{ fontSize: 24, color: row.satisfaction >= 4 ? "red" : "#ccc" }} />
                        <HeartOutlined style={{ fontSize: 24, color: row.satisfaction === 5 ? "red" : "#ccc" }} />
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Table
                scroll={{ x: 1000 }}
                pagination={props.pagination}
                onChange={props.onChange}
                loading={props.loading}
                dataSource={props.reports}
                columns={columns.filter(c => c.visible)} />
        </>
    );
};

export default SatisfactionListview;
