import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Layout, Menu, Modal, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import 'antd/dist/antd.css';
import './App.css';
import {
    CalendarOutlined,
    DashboardOutlined, FormOutlined,
    LockOutlined,
    LogoutOutlined, PauseCircleOutlined, PhoneOutlined,
    SettingOutlined,
    TagsOutlined,
    User,
    UserOutlined,
    CarOutlined,
} from "@ant-design/icons";

import CareRequests from "./CareRequests";
import UserService from "./userService";
import Reports from "./Reports";
import Settings from "./Settings";
import OnHolds from "./OnHolds";
import Appointments from "./Appointments";
import Prescriptions from "./Prescriptions";
import Dashboard from "./Dashboard";

import ExceptionOutlined from "@ant-design/icons/lib/icons/ExceptionOutlined";
import SalesReport from "./SalesReport";
import {AlertOutlined} from "@ant-design/icons";
import AdverseEvents from "./AdverseEvents";
import Deliveries from "./Deliveries";

const { Header, Content, Footer, Sider } = Layout;

const { Option, OptGroup } = Select;

const handleLogout = () => {
    sessionStorage.removeItem("sessionUser");
    sessionStorage.removeItem("multiAuth");
    return window.location.href = "/";
};

const Panel = () => {
    const { t, i18n } = useTranslation();
    const [selectedMenu, setSelectedMenu] = useState("1");
    const [me, setMe] = useState({
        id: 0,
        fullname: "",
        company: "Testziekenhuis",
        company_id: undefined,
        mayLoginAs: [],
        role_id: 0,
    });
    const [selectedCpId, setSelectedCpId] = useState(undefined);
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [username, setUsername] = useState(undefined);
    const [password, setPassword] = useState(undefined);

    useEffect(() => {
        let userToken = UserService.getDecodedToken();
        userToken.mayLoginAs = UserService.getMayLoginAs();
        setMe(userToken)
    }, []);

    useEffect(() => {
        if (selectedCpId > 0) {
            setLoginModalVisible(true);
        }
    }, [selectedCpId]);

    const handleLoginAs = () => {
        UserService.authenticate(username, password, "", true, selectedCpId).then(() => {
            window.location.reload();
        })
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Modal visible={loginModalVisible} onCancel={() => setLoginModalVisible(false)} onOk={handleLoginAs}>
                <label>Ter controle dien je nogmaals je eigen gebruikersnaam en wachtwoord in te voeren.</label>
                <Form.Item>
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Gebruikersnaam"
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Wachtwoord"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                </Form.Item>
            </Modal>

            <Sider style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}>
                <img src={"logo-" + UserService.getCustomer().toLowerCase() + ".png"} style={{width: UserService.getCustomer().toLowerCase() === "bleds" ? 170 : 100, marginTop: 20, marginBottom: 20}} alt="logo" />

                {me.company_id && me.company_id !== 8404 && (
                    <Menu theme="dark" defaultSelectedKeys={[selectedMenu]} style={{textAlign: "left"}}>
                        {/*<Menu.Item key="1" onClick={() => setSelectedMenu("1")}>*/}
                        {/*    <DashboardOutlined />*/}
                        {/*    <span className="nav-text">Dashboard</span>*/}
                        {/*</Menu.Item>*/}
                        {(me.role_id === 72 || me.role_id === 73) && (
                            <Menu.Item key="2" onClick={() => setSelectedMenu("2")}>
                                <TagsOutlined />
                                <span className="nav-text">{t('careRequests')}</span>
                            </Menu.Item>
                        )}
                        {/*<Menu.Item key="3" onClick={() => setSelectedMenu("3")}>*/}
                        {/*    <PauseCircleOutlined/>*/}
                        {/*    <span className="nav-text">{t('onHolds')}</span>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="4" onClick={() => setSelectedMenu("4")}>*/}
                        {/*    <FormOutlined/>*/}
                        {/*    <span className="nav-text">{t('reports')}</span>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="5" onClick={() => setSelectedMenu("5")}>*/}
                        {/*    <CalendarOutlined/>*/}
                        {/*    <span className="nav-text">{t('appointments')}</span>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="6" onClick={() => setSelectedMenu("6")}>*/}
                        {/*    <ExceptionOutlined/>*/}
                        {/*    <span className="nav-text">Recepten</span>*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item key="7" onClick={() => setSelectedMenu("7")}>*/}
                        {/*    <AlertOutlined/>*/}
                        {/*    <span className="nav-text">Meldingen</span>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item key="8" onClick={() => setSelectedMenu("8")}>
                            <CarOutlined/>
                            <span className="nav-text">Leveringen</span>
                        </Menu.Item>

                        <div style={{position: "absolute", bottom: 40, left: 6, textAlign: "center"}}>
                            <h3 style={{marginBottom: 20, color: "white", marginLeft: 15}}>IT-helpdesk</h3>
                            <Button icon={<PhoneOutlined/>} ghost shape={"round"} style={{marginLeft: 20}} title={"Contact opnemen met de helpdesk"} onClick={() => console.log('x')}>085-1309293</Button>
                        </div>
                    </Menu>
                )}

                {me.company_id && me.company_id === 8404 && (
                    <Menu theme="dark" defaultSelectedKeys="1000" style={{textAlign: "left"}}>
                        <Menu.Item key="1000" onClick={() => setSelectedMenu("1000")}>
                            <DashboardOutlined/>
                            <span className="nav-text">Sales rapportage</span>
                        </Menu.Item>

                        <div style={{position: "absolute", bottom: 40, left: 6, textAlign: "center"}}>
                            <h3 style={{marginBottom: 20, color: "white", marginLeft: 15}}>IT-helpdesk</h3>
                            <Button icon={<PhoneOutlined/>} ghost shape={"round"} style={{marginLeft: 20}} title={"Contact opnemen met de helpdesk"} onClick={() => console.log('x')}>085-1309293</Button>
                        </div>
                    </Menu>
                )}
            </Sider>
            <Layout>
                <Header style={{width: "100%", margin: 0, padding: 0, paddingRight: 10}}>
                    <div style={{width: "100%", textAlign: "right", color: "#fff"}}>
                        {me.mayLoginAs && me.mayLoginAs.length > 0 && (
                            <Select style={{marginRight: 20, width: 200}} placeholder={"Inloggen als..."} onChange={(value) => setSelectedCpId(value)}>
                                {me.mayLoginAs.map((hos, hosIndex) => {
                                    return (
                                        <OptGroup label={hos.re_naam} key={hosIndex}>
                                            {hos.users.map((hosUser, index) => {
                                                return (
                                                    <Option value={hosUser.cp_id} key={`${hosIndex}_${index}`}>{hosUser.username}</Option>
                                                )
                                            })}
                                        </OptGroup>
                                    )
                                })}
                            </Select>
                        )}
                        <UserOutlined />
                        {" "}
                        {(me.role_id === 72 || me.role_id === 72) && <span>Hospital</span>}
                        {me.role_id === 38 && <span>Pharmacy</span>}
                        {me.role_id === 211 && <span>Supplier</span>}
                        {me.role_id === 212 && <span>Transporter</span>}
                        {` @ ${me.company}`}
                        <Button icon={<SettingOutlined />} ghost shape={"round"} style={{marginLeft: 10}} title={"Instellingen"} onClick={() => setSelectedMenu("100")} />
                        <Button icon={<LogoutOutlined />} ghost shape={"round"} style={{marginLeft: 10}} title={"Uitloggen"} onClick={() => handleLogout()} />
                    </div>
                </Header>
                <Content style={{ marginTop: 0, textAlign: "left", padding: 10, paddingLeft: 210, background: "white" }}>
                    {me.company_id !== 8404 && selectedMenu === "1" ? <Dashboard /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "2" ? <CareRequests /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "3" ? <OnHolds /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "4" ? <Reports /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "5" ? <Appointments /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "6" ? <Prescriptions /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "7" ? <AdverseEvents /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "8" ? <Deliveries /> : undefined}
                    {me.company_id !== 8404 && selectedMenu === "100" ? <Settings /> : undefined}
                    {selectedMenu === "1000" || me.company_id === 8404 ? <SalesReport /> : undefined}
                </Content>
                <Footer style={{ textAlign: 'center', paddingLeft: 200 }}>© {UserService.getCustomer()} - Powered by Yulp! - All rights reserved - 2021</Footer>
            </Layout>
        </Layout>
    );
};

export default Panel;
