import "whatwg-fetch";

class UserService {
    static getCustomer () {
        // let hostname = window.location.hostname;
        // hostname = hostname.split(".");
        // if (hostname.length > 1) {
        //     return hostname[1] === "bleds" ? "Bleds" : "Yulp";
        // }

        // return "Yulp";
        // return "Bleds";
        return "Abrix";
    };

    static isLoggedIn() {
        let jwtDecode = require("jwt-decode");
        let token = sessionStorage.getItem("sessionUser");

        if (!token) {
            return false;
        }

        token = JSON.parse(token);
        token = (token && token.jwt) ? jwtDecode(token.jwt) : false;

        return (token && token.username && token.username.length);
    }

    static isMultiAuth() {
        let jwtDecode = require("jwt-decode");
        let token = JSON.parse(sessionStorage.getItem("sessionUser"));
        token = (token && token.jwt) ? jwtDecode(token.jwt) : false;

        return (token && (!token.needs_2fa || token.passed_2fa));
    }

    static getToken() {
        let token = JSON.parse(sessionStorage.getItem("sessionUser"));
        token = (token && token.jwt) ? token.jwt : false;

        return token;
    }

    static getDecodedToken() {
        let jwtDecode = require("jwt-decode");
        return jwtDecode(UserService.getToken());
    }

    static getMayLoginAs() {
        const token = JSON.parse(sessionStorage.getItem("sessionUser"));
        if (token && token.mayLoginAs) {
            return token.mayLoginAs;
        }

        return []
    }

    static hasRole(role) {
        let jwtDecode = require("jwt-decode");
        let token = JSON.parse(sessionStorage.getItem("sessionUser"));
        token = (token && token.jwt) ? jwtDecode(token.jwt) : false;

        return (role.toLowerCase() === token.role.toLowerCase());
    }

    static async authenticate(username, password, otp, storeToken, cp_id) {
        /**
         * If we are in DEV, replace the api URI with the dev URI
         */
        let baseUrl = "https://api.bleds.conexus.nl";

        let hostname = window.location.hostname;
        hostname = hostname.split(".");
        let customer = hostname[0];

        if (customer === "localhost") {
            baseUrl = "https://api.bleds.conexus.nl";
        }

        let token = this.getToken();

        sessionStorage.removeItem("sessionUser");

        let headers = {};
        if (cp_id !== undefined) {
            headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            };
        }
        else {
            headers = {
                "Accept": "application/json",
                "Content-Type": "application/json"
            };
        }

        headers = new Headers(headers);

        const cpId = cp_id !== undefined ? cp_id : '0';

        return fetch(baseUrl + "/v1/authenticate", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                username: username,
                password: password,
                otp: otp,
                cp_id: cpId
            }),
        }).then(function(response) {
            if (!response.ok) {
                return false;
            }

            return response.json();
        }).then(async function(json) {
            if (json && json.jwt) {
                let sessionUser = {
                    jwt: json.jwt,
                    mayLoginAs: json.mayLoginAs
                };

                if (storeToken) {
                    await sessionStorage.setItem("sessionUser", JSON.stringify(sessionUser));
                }

                let jwtDecode = require("jwt-decode");
                return jwtDecode(json.jwt);
            }

            return false;
        });
    }

    static authenticateMulti() {
        sessionStorage.removeItem("multiAuth");
        sessionStorage.setItem("multiAuth", JSON.stringify({
            authenticated: true
        }));
    }

    static authFetch(url, options)
    {
        url = "https://api.bleds.conexus.nl" + url;

        let token = this.getToken();

        if (!options.headers) {
            options.headers = {};
        }

        if (token.length) {
            options.headers["Authorization"] = "Bearer " + token;
        }

        options.headers = new Headers(options.headers);

        return fetch(url, options);
    }

    getMe() {
        return UserService.authFetch("/v1/users/me", {
            method: "GET"
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            return data;
        });
    }
}

export default UserService;
