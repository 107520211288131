import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Row,
    Select,
    Table,
    Tabs,
    DatePicker,
    Pagination,
    Timeline, Checkbox, Card, Typography
} from 'antd';
import {
    CalendarOutlined,
    CalendarTwoTone,
    CheckCircleTwoTone, FileExcelOutlined, FilePdfOutlined,
    HourglassTwoTone,
    PauseCircleTwoTone, PlusCircleOutlined,
    UserSwitchOutlined,
    EditOutlined,
} from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"
import TimelineItem from "antd/lib/timeline/TimelineItem";
import Title from "antd/lib/typography/Title";

moment.locale("nl");

const DeliveryForm = (props) => {
    const [ready, setReady] = useState(false)
    const [pickedUp, setPickedUp] = useState(false)
    const [delivered, setDelivered] = useState(false)

    const { Title } = Typography

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Col>
                    <Title level={5}>Patiëntinfo</Title>
                    <Title level={4}>Berg, Joost</Title>
                    <label>
                        BSN
                        <h4>123412012</h4>
                    </label>
                    <label>
                        Geboortedatum
                        <h4>13-09-1986</h4>
                    </label>
                    <label>
                        Adres
                        <h4>Legakker 1</h4>
                        <h4>7596KE ROSSUM</h4>
                    </label>
                </Col>
            </Col>
            <Col span={10}>
                <Title level={5}>Info over levering</Title>
                <Form.Item label="Type levering">
                    <Select style={{width: 200}} value={"2"}>
                        <Select.Option key="1" value="1">Medicaties</Select.Option>
                        <Select.Option key="2" value="2">Supplies</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Leveren voor">
                    <DatePicker showTime format={"DD-MM-YYYY HH:mm"} />
                </Form.Item>
                <Form.Item label="Opmerkingen">
                    <TextArea style={{ width: 400 }} />
                </Form.Item>
                <Form.Item label="Herhaling instellen">
                    <Select style={{ width: 400 }} value={"2"}>
                        <Select.Option key="1" value="1">Wekelijks</Select.Option>
                        <Select.Option key="2" value="2">2-wekelijks</Select.Option>
                        <Select.Option key="3" value="3">3-wekelijks</Select.Option>
                        <Select.Option key="4" value="4">4-wekelijks</Select.Option>
                        <Select.Option key="5" value="5">Maandelijks</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Einddatum herhaling">
                    <DatePicker format={"DD-MM-YYYY"} />
                </Form.Item>

                <Divider />

                <Title level={5}>Artikelen</Title>
                <Form labelAlign="right" layout="horizontal">
                    <Row>
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Form.Item label="Artikel 1">
                                <Select style={{width: 350}}>
                                    <Select.Option value={"1"}>Afsluitdopje LL M/F</Select.Option>
                                    <Select.Option value={"2"}>Bionecteur Swanlock</Select.Option>
                                    <Select.Option value={"3"}>Bodyguard inf.syst.1.2mu spike 2.7m TPN00001</Select.Option>
                                    <Select.Option value={"4"}>Flesje Chloorhexed 0,5%/Alco 70% 100ml</Select.Option>
                                    <Select.Option value={"5"}>Gaascompress 10x10cm steriel verpakt per 2 stuks</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Form.Item label="Artikel 2">
                                <Select style={{width: 350}}>
                                    <Select.Option value={"1"}>Afsluitdopje LL M/F</Select.Option>
                                    <Select.Option value={"2"}>Bionecteur Swanlock</Select.Option>
                                    <Select.Option value={"3"}>Bodyguard inf.syst.1.2mu spike 2.7m TPN00001</Select.Option>
                                    <Select.Option value={"4"}>Flesje Chloorhexed 0,5%/Alco 70% 100ml</Select.Option>
                                    <Select.Option value={"5"}>Gaascompress 10x10cm steriel verpakt per 2 stuks</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Form.Item label="Artikel 3">
                                <Select style={{width: 350}}>
                                    <Select.Option value={"1"}>Afsluitdopje LL M/F</Select.Option>
                                    <Select.Option value={"2"}>Bionecteur Swanlock</Select.Option>
                                    <Select.Option value={"3"}>Bodyguard inf.syst.1.2mu spike 2.7m TPN00001</Select.Option>
                                    <Select.Option value={"4"}>Flesje Chloorhexed 0,5%/Alco 70% 100ml</Select.Option>
                                    <Select.Option value={"5"}>Gaascompress 10x10cm steriel verpakt per 2 stuks</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 5 }}>
                            <Button>Extra artikel</Button>
                        </Col>
                    </Row>
                    <Row style={{display: "none"}}>
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} checked />
                        </Col>
                        <Col span={22}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                tooltip={
                                    <>
                                        <Row>
                                            <Col><strong>Infusion frequency: </strong></Col>
                                            <Col>TWICE each week</Col>
                                        </Row>
                                        <Row>
                                            <Col><strong>Infusion days:&nbsp;</strong></Col>
                                            <Col>monday, thursday</Col>
                                        </Row>
                                        <Row>
                                            <Col><strong>Infusion time:&nbsp;</strong></Col>
                                            <Col>8 hours</Col>
                                        </Row>
                                    </>
                                }
                                label="Parenteral Nutrition Bag 1: Periomegomel E 1450ml" />
                        </Col>
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} />
                        </Col>
                        <Col span={22}>
                            <Form.Item style={{ marginBottom: 0 }} tooltip="test" label="Parenteral Nutrition Bag 2: Olimel N4E 2,000 ml" />
                        </Col>
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} />
                        </Col>
                        <Col span={22}>
                            <Form.Item style={{ marginBottom: 0 }} tooltip="test" label="Emergency unprepared bag: Omegomel E 1085ml"></Form.Item>
                        </Col>
                        <Divider />
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} />
                        </Col>
                        <Col span={22}>
                            <Form.Item style={{ marginBottom: 0 }} label={<Input placeholder="Extra artikel" disabled={ready} />} colon={false} />
                        </Col>
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} />
                        </Col>
                        <Col span={22}>
                            <Form.Item style={{ marginBottom: 0 }} label={<Input placeholder="Extra artikel" disabled={ready} />} colon={false} />
                        </Col>
                        <Col span={2} style={{ marginTop: 5 }}>
                            <Checkbox disabled={ready} />
                        </Col>
                        <Col span={22}>
                            <Form.Item style={{ marginBottom: 0 }} label={<Input placeholder="Extra artikel" disabled={ready} />} colon={false} />
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col span={8}>
                <Title level={5}>Tijdslijn</Title>
                <Timeline>
                    <TimelineItem color="green">
                        <Form.Item label="Voorbereiding gepland" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker showTime format={"DD-MM-YYYY HH:mm"} />
                                <Button>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                    <TimelineItem color={ready ? "green" : "grey"}>
                        <Form.Item label="Voorbereiding klaar" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker showTime format={"DD-MM-YYYY HH:mm"} />
                                <Button>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        <Form.Item label="Ophalen gepland" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker showTime format={"DD-MM-YYYY HH:mm"} disabled />
                                <Button disabled>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        <Form.Item label="Ophalen klaar" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker disabled showTime format={"DD-MM-YYYY HH:mm"} />
                                <Button disabled>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        <Form.Item label="Afleveren gepland (tussen ... en ...)" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker disabled showTime format={"DD-MM-YYYY HH:mm"} placeholder="Tijdsblok begin" />
                                <DatePicker disabled showTime format={"DD-MM-YYYY HH:mm"} placeholder="Tijdsblok eind" />
                                <Button disabled>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        <Form.Item label="Afleveren klaar" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <DatePicker showTime format={"DD-MM-YYYY HH:mm"} disabled={!ready} />
                                <Button disabled={!ready}>Opslaan</Button>
                            </Input.Group>
                        </Form.Item>
                    </TimelineItem>
                </Timeline>
            </Col>
        </Row>
    )
}

const DeliveriesTable = (props) => {
    const { t, i18n } = useTranslation();

    const columns = [
        {
            title: 'Patient ID',
            key: 'patientId',
            fixed: 'left',
            width: 150,
            visible: props.showPatientId,
            render: (index, record) => {
                if (record.rowType === "uvo") { // Afspraak, child row
                    return <span><CalendarOutlined /> {record.patientId}</span>
                }
                return (
                    <span>{record.patientId}</span>
                );
            }
        },
        {
            title: "Uiterste levering",
            dataIndex: 'deliveryBefore',
            key: 'deliveryBefore',
            fixed: 'left',
            width: 170,
            visible: true,
        },
        {
            title: "Soort",
            dataIndex: 'type',
            key: 'type',
            width: 100,
            visible: true,
        },
        {
            title: "Voorbereiden",
            key: 'preparations',
            visible: true,
            style: { background: "#ccc !important" },
            children: [
                {
                    title: "Gepland",
                    dataIndex: 'preparationsPlanned',
                    key: 'preparationsPlanned',
                    width: 170,
                    visible: true,
                },
                {
                    title: "Gereed",
                    dataIndex: 'preparationsDone',
                    key: 'preparationsDone',
                    width: 170,
                    visible: true,
                },
            ],
        },
        {
            title: "Ophalen",
            key: 'pickup',
            visible: true,
            children: [
                {
                    title: "Gepland",
                    dataIndex: 'pickupPlanned',
                    key: 'pickupPlanned',
                    width: 170,
                    visible: true,
                },
                {
                    title: "Gereed",
                    dataIndex: 'pickupDone',
                    key: 'pickupDone',
                    width: 170,
                    visible: true,
                },
            ],
        },
        {
            title: "Afleveren",
            key: 'delivery',
            visible: true,
            children: [
                {
                    title: "Gepland",
                    dataIndex: 'deliveryPlanned',
                    key: 'pickupPlanned',
                    width: 170,
                    visible: true,
                },
                {
                    title: "Gereed",
                    dataIndex: 'deliveryDone',
                    key: 'deliveryDone',
                    width: 170,
                    visible: true,
                },
            ],
        },
        {
            title: "",
            key: 'actions',
            visible: true,
            fixed: "right",
            width: 75,
            render: (text, row) => {
                return (<EditOutlined onClick={() => props.setShowForm(true)} />)
            }
        }
    ];

    return (
        <>
            {props.showForm && <Modal
                onClose={() => props.setShowForm(false)}
                onCancel={() => props.setShowForm(false)}
                visible={props.showForm}
                title="Levering"
                width={1200}
                destroyOnClose
            >
                <DeliveryForm />
            </Modal>}

            <Form layout="vertical">
                <Row gutter={[16,8]} style={{paddingTop: 20}}>
                    <Col sm={6}>
                        <Form.Item label={"Zoeken"}>
                            <Input.Search
                                placeholder="Zoeken"
                                onSearch={value => console.log(value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum vanaf"}>
                            <DatePicker onChange={(e) => console.log(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={"Datum t/m"}>
                            <DatePicker onChange={(e) => console.log(e)} format={"DD-MM-YYYY"} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Row gutter={[16,8]}>
                <Col span={12}>
                    <Button icon={<FileExcelOutlined/>} style={{marginRight: 10}} onClick={() => console.log('x')} loading={false}>Export Excel</Button>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    {!props.hideAddButton && (
                        <Button key="1" type="primary" onClick={() => props.setShowForm(true)}>
                            <PlusCircleOutlined />
                            Nieuwe levering
                        </Button>
                    )}
                </Col>
            </Row>

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <Table
                        scroll={{ x: 600 }}
                        bordered
                        pagination={false}
                        onChange={() => console.log('change')}
                        loading={false}
                        className={"custom-table"}
                        rowClassName={(record) => {
                            if (record.rowType === "uvo") {
                                return "child-row-level-1";
                            }
                            return "parent-row";
                        }}
                        dataSource={props.deliveries}
                        columns={columns.filter((c) => c.visible)} />
                </Col>
            </Row>
        </>
    );
}

export default DeliveriesTable
