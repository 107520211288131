import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Row,
    Select,
    Table,
    Tabs,
    DatePicker,
    Pagination,
    Timeline, Checkbox
} from 'antd';
import {
    CalendarOutlined,
    CalendarTwoTone,
    CheckCircleTwoTone, FileExcelOutlined, FilePdfOutlined,
    HourglassTwoTone,
    PauseCircleTwoTone, PlusCircleOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import CareRequestForm from "./CareRequestForm";
import UserService from "./userService";
import CareRequest from "./CareRequest";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useTranslation} from 'react-i18next';
import 'moment/locale/nl';
import {debounce} from "lodash"
import TimelineItem from "antd/lib/timeline/TimelineItem";
import Title from "antd/lib/typography/Title";
import DeliveriesTable from "./shared/DeliveriesTable";

moment.locale("nl");

const DeliveryForm = (props) => {
    const [ready, setReady] = useState(false)
    const [pickedUp, setPickedUp] = useState(false)
    const [delivered, setDelivered] = useState(false)

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Title level={5}>Patientinfo</Title>
                <label>Patient ID: </label>
                YLP-100001
            </Col>
            <Col span={12}>
                <Title level={5}>Info over levering</Title>
                <Form.Item label="Leveren voor">
                    <DatePicker showTime format={"DD-MM-YYYY HH:mm"} />
                </Form.Item>
                <Form.Item label="Opmerkingen">
                    <TextArea style={{ width: 400 }} />
                </Form.Item>

                <Title level={5}>Artikelen</Title>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col>Parenteral Nutrition Bag 1: Olimel N7E 1,000 ml</Col>
                </Row>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col>Parenteral Nutrition Bag 2: Clinimix N14G30 2,000 ml</Col>
                </Row>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col>Emergency unprepared bag: Omegomel E 1085ml</Col>
                </Row>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col><Input placeholder="Extra artikel" disabled={ready} /></Col>
                </Row>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col><Input placeholder="Extra artikel" disabled={ready} /></Col>
                </Row>
                <Row>
                    <Col span={1}><Checkbox disabled={ready} /></Col>
                    <Col><Input placeholder="Extra artikel" disabled={ready} /></Col>
                </Row>
            </Col>
            <Col span={6}>
                <Title level={5}>Acties</Title>
                <Timeline>
                    <TimelineItem color="green">
                        13-04: Aangemaakt
                    </TimelineItem>
                    <TimelineItem color={ready ? "green" : "grey"}>
                        {!ready && <Button onClick={() => setReady(true)}>Klaar voor verzenden</Button>}
                        {ready && <span>13-04: Klaar voor verzenden</span>}
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        {!pickedUp && <Button disabled={!ready} onClick={() => setPickedUp(true)}>Opgehaald transporteur</Button>}
                        {pickedUp && <span>13-04: Opgehaald transporteur</span>}
                    </TimelineItem>
                    <TimelineItem color={pickedUp ? "green" : "grey"}>
                        {!delivered && <Button disabled={!pickedUp} onClick={() => setDelivered(true)}>Afgeleverd bij patient</Button>}
                        {delivered && <span>13-04: Afgeleverd bij patient</span>}
                    </TimelineItem>
                </Timeline>
            </Col>
        </Row>
    )
}

const Deliveries = (props) => {
    const { t, i18n } = useTranslation();
    const [showForm, setShowForm] = useState(false)

    const deliveries = [
        // {
        //     id: 1,
        //     patientId: "YLP-100001",
        //     status: "Nieuw",
        //     deliveryBefore: "20-04-2021 12:00",
        //     type: "Medicatie",
        //     preparationsPlanned: "15-04-2021 16:30",
        //     preparationsDone: "-",
        //     pickupPlanned: "-",
        //     pickupDone: "-",
        //     deliveryPlanned: "-",
        //     deliveryDone: "-",
        // },
    ]

    return (
        <>
            <PageHeader
                style={{
                    border: '1px solid rgb(235, 237, 240)',
                    marginBottom: 10
                }}
                title="Leveringen"
                subTitle={t("overview")}
            />

            <Row gutter={[16,8]}>
                <Col span={24}>
                    <DeliveriesTable
                        deliveries={deliveries}
                        showForm={showForm}
                        setShowForm={setShowForm}
                        showPatientId={true}
                        hideAddButton={true}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Deliveries

